<template>
  <div>
    <b-modal
        id="modal-subscribe-user-to-report"
        ref="modal-subscribe-user-to-report"
        footer-class="flex-nowrap"
        no-close-on-esc
        no-close-on-backdrop
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.addUser.title') }}</h3>
      </template>

      <template #default>
        <div class="mt-4 mx-5 mb-3">
          <b-form-input
              id="name-input"
              v-model="name"
              :placeholder="$t('modals.addUser.name')"
              @update="setName"
              autofocus
              debounce="500"
              @keyup.enter="addUser"
          >
          </b-form-input>
        </div>
        <div class="mt-4 mx-5 mb-3">
          <b-form-input
              id="email-input"
              v-model="userEmail"
              v-model.trim="$v.form.email.$model"
              :placeholder="`${$t('modals.addUser.email')}*`"
              :state="validateState()"
              aria-describedby="email-live-feedback"
              @update="setEmail"
              debounce="500"
              @keyup.enter="addUser"
          >
          </b-form-input>
          <b-form-invalid-feedback id="email-live-feedback">
            {{ $t('login.invalidEmail') }}
          </b-form-invalid-feedback>
        </div>
      </template>

      <template #modal-footer>
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="$emit('cancel')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            variant="primary"
            :disabled="!validEmail"
            class="p-3"
            block
            @click="addUser"
        >
          {{ $t('modals.addUser.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'ModalSubscribeUserToReport',
  mixins: [validationMixin],
  data: () => ({
    name: null,
    userEmail: null,
    validEmail: false,
    form: {
      email: null,
    },
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  mounted() {
    this.$refs['modal-subscribe-user-to-report'].show();
  },
  methods: {
    validateState() {
      const { $dirty, $error } = this.$v.form.email;
      return $dirty ? !$error : null;
    },
    setName(value) {
      this.name = value;
    },
    setEmail(value) {
      this.userEmail = value;
      if (!this.$v.form.$anyError) {
        this.validEmail = true;
      } else {
        this.validEmail = false;
      }
    },
    async addUser() {
      if (this.validEmail) {
        this.$emit('add-user', this.userEmail, this.name);
      }
    },
  },
};
</script>

<style scoped>

</style>
