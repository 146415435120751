var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        ref: "modal-subscribe-user-to-report",
        attrs: {
          id: "modal-subscribe-user-to-report",
          "footer-class": "flex-nowrap",
          "no-close-on-esc": "",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [_vm._v(_vm._s(_vm.$t("modals.addUser.title")))])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "mt-4 mx-5 mb-3" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "name-input",
                        placeholder: _vm.$t("modals.addUser.name"),
                        autofocus: "",
                        debounce: "500"
                      },
                      on: {
                        update: _vm.setName,
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.addUser.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-4 mx-5 mb-3" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "email-input",
                        placeholder: `${_vm.$t("modals.addUser.email")}*`,
                        state: _vm.validateState(),
                        "aria-describedby": "email-live-feedback",
                        debounce: "500"
                      },
                      on: {
                        update: _vm.setEmail,
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.addUser.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.$v.form.email.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.form.email,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "$v.form.email.$model"
                      }
                    }),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { id: "email-live-feedback" } },
                      [_vm._v(" " + _vm._s(_vm.$t("login.invalidEmail")) + " ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("cancel")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      variant: "primary",
                      disabled: !_vm.validEmail,
                      block: ""
                    },
                    on: { click: _vm.addUser }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("modals.addUser.validate")) + " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }